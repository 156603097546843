<template>
	<section class="promotions background-light main-content align-center">
		<div class="row row-header align-center">
			<div class="pre-header-logo" />
			<h2 class="subtitle">{{ subtitle }}</h2>
			<h2>{{ header }}</h2>
			<div v-parse-links v-html="content" />
		</div>
		<div class="row promotions-row flex-row slide-row">
			<client-only>
				<nuxt-link
					v-for="room in items.slice(0, 4)"
					:key="room.ID"
					:to="localePath(`/${room.filename}`)"
					class="slick-item"
				>
					<picture>
						<source
							v-if="room.imageOverviewPortraitWebp"
							:srcset="room.imageOverviewPortraitWebp"
							type="image/webp"
						/>
						<source :srcset="room.imageOverviewPortrait" />
						<img loading="lazy" :src="room.imageOverviewPortrait" :alt="room.imageOverviewPortraitAlt" />
					</picture>
					<div class="slick-slide-content align-left">
						<h3>{{ room.header }}</h3>
						<span>
							{{ room.button }}
							<font-awesome-icon icon="fa-light fa-arrow-right-long" size="1x" />
						</span>
					</div>
				</nuxt-link>
			</client-only>
		</div>

		<div class="row">
			<div class="columns column12 align-center">
				<nuxt-link :to="localePath('rooms')" class="button-no-fill">
					{{ defaults[locale].rooms.more }}
				</nuxt-link>
			</div>
		</div>
	</section>
</template>

<script setup>
const { defaults, fetchDefaults } = useDefaults();
const { locale } = useI18n();

await fetchDefaults();

defineProps({
	items: { type: Array, default: () => [] },
	subtitle: { type: String, default: '' },
	header: { type: String, default: '' },
	content: { type: String, default: '' },
});
</script>

<style lang="scss" scoped>
.promotions {
	background: var(--primary-background-color);
}

.slide-row {
	justify-content: space-around;
}

.slick-item {
	width: 48%;
	margin: 0 0 2%;

	picture {
		position: relative;

		&::after {
			display: block;
			content: '';
			inset: 1px;
			position: absolute;
			background: #fff;
			z-index: 1;
		}
	}

	img {
		opacity: 1;
		transition: opacity 0.3s ease-in-out;
		position: relative;
		z-index: 2;
	}

	&:hover {
		img {
			opacity: 0.92;
		}
	}
}

.row-header {
	max-width: 980px;
}
</style>
